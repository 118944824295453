import React, { useEffect } from 'react'
import { useAuth } from '../context/AuthContext';

import Nav from '../components/MainPage/Nav'
import Dashboard from '../components/MainPage/Dashboard'
import Sidebar from '../components/MainPage/Sidebar'

const MainPage = () => {
  const {
    scheduledAudits,
    setScheduledAudits,
    inProgressAudits,
    setInProgressAudits,
    currentPage,
    setCurrentPage,
    setCreatePageNumber,
    currentAudit,
    setCurrentAudit,
    allAudits,
    setAllAudits,
    toCheckAudits,
    setToCheckAudits,
    toSendAudits,
    setToSendAudits,
    sentAudits,
    setSentAudits,
  } = useAuth() || {};



  const { authTokens } = useAuth();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  // console.log(authTokens?.access)
  useEffect(() => {
    if (!scheduledAudits.length && !inProgressAudits.length && !toCheckAudits.length && !toSendAudits.length && !sentAudits.length) {

    fetch(`${backendUrl}/audit-page/audits/`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + (authTokens?.access || '')
      }
    })
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data)) {
          const scheduledAudits = data.filter(audit => audit.status === "Scheduled");
          const inProgressAudits = data.filter(audit => audit.status === "In Progress");
          const toCheckAudits = data.filter(audit => audit.status === "To Check");
          const toSendAudits = data.filter(audit => audit.status === "To Send");
          const sentAudits = data.filter(audit => audit.status === "Sent");
          console.log(sentAudits)
          setScheduledAudits(scheduledAudits);
          setInProgressAudits(inProgressAudits);
          setToCheckAudits(toCheckAudits);
          setToSendAudits(toSendAudits);
          setSentAudits(sentAudits);
          setAllAudits(data);
          console.log("inProgressAudits")
          console.log(inProgressAudits)
        } else {
          console.error('Oczekiwano tablicy, ale otrzymano:', data);
        }
      })
      .catch(error => {
        console.error('Błąd:', error);
        setScheduledAudits([]);
        setInProgressAudits([]);
        setToCheckAudits([]);
        setToSendAudits([]);
        setSentAudits([]);
        setAllAudits([]);
      });
    }
  }, [authTokens?.access, backendUrl, setScheduledAudits, inProgressAudits.length, scheduledAudits.length, setAllAudits, setInProgressAudits]);


  return (
    <div className='lg:flex '>
      <Nav currentPage={currentPage} setCurrentPage={setCurrentPage} setCreatePageNumber={setCreatePageNumber} />
      <Dashboard currentPage={currentPage} scheduledAudits={scheduledAudits} setScheduledAudits={setScheduledAudits} inProgressAudits={inProgressAudits} setInProgressAudits={setInProgressAudits} toCheckAudits={toCheckAudits} setToCheckAudits={setToCheckAudits} toSendAudits={toSendAudits} setToSendAudits={setToSendAudits} sentAudits={sentAudits} setSentAudits={setSentAudits} currentAudit={currentAudit} setCurrentAudit={setCurrentAudit} allAudits={allAudits} setCurrentPage={setCurrentPage} />
      {/* <div className='hidden lg:flex'>
        <Sidebar scheduledAudits={scheduledAudits} sentAudits={sentAudits} currentAudit={currentAudit} setCurrentAudit={setCurrentAudit} setCurrentPage={setCurrentPage} />
      </div> */}
    </div>
  )
}

export default MainPage