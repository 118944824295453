import { Document, Page, Text, View, Image, Link as PdfLink } from '@react-pdf/renderer';
import React from 'react';
import styles from './PdfStyles';
import Logo from '../../utils/pdfs/hotels-audit-logo.png';
import backgroundImage from '../../utils/pdfs/raport-bg.png';
import diamond from '../../utils/pdfs/diamond-certification.png'
import platinum from '../../utils/pdfs/platinum-certification.png'
import gold from '../../utils/pdfs/gold-certification.png'
import ceoSignature from '../../utils/pdfs/signature.png'

function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Miesiące są od 0 do 11
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}

const QuestionSection = React.memo(({ question, answer, actionItems, note, aiResponse, photoSrc, questionNumber }) => {
    // Determine the color based on the answer
    const answerColor = answer === 'yes' ? '#40e14b' : answer === 'no' ? '#ff8484' : 'gray';
    // const shouldDisplayAIResponse = (answer || 'yes').toLowerCase() === 'no'

    return (
        <View wrap={false}>
            <View style={styles.errorQuestionContainer} break>
                <View style={photoSrc ? styles.errorQuestionContentWithPhoto : styles.errorQuestionContent}>
                    <View style={styles.questionAndAnswerContainer}>
                        <View style={[styles.answerIndicator, { borderColor: answerColor }]}>
                            <Text style={[styles.answerText, { color: answerColor }]}> {answer}</Text>
                        </View>
                        <Text style={styles.questionText} >{questionNumber}. {question}</Text>
                    </View>
                    {/* {note && (
                        <View style={{ paddingTop: 10, paddingBottom: 5, marginLeft: 70 }}>
                            <View style={styles.noteText}>
                                <Text style={{ fontWeight: 'bold' }}>COMMENT:</Text> <Text>{note}</Text>
                            </View>

                        </View>
                    )} */}
                    {aiResponse && (
                        <View style={{ marginLeft: 70, paddingTop: 5, paddingBottom: 10 }}>
                            <View style={styles.noteText}>
                                <Text style={{ fontWeight: 'bold' }}>RESPONSE:</Text> <Text>{aiResponse}</Text>
                            </View>
                        </View>
                    )}
                    {photoSrc && Array.isArray(photoSrc) && photoSrc.length > 0 && (
                        <View style={styles.photoContainer}>
                            {photoSrc.map((photo, index) => (
                                <PdfLink src={photo.originalUrl} key={index}>
                                    <Image style={styles.photo} source={{ uri: photo.thumbnailUrl }} />
                                </PdfLink>
                            ))}
                        </View>
                    )}
                </View>
            </View>
        </View>
    );
});

const AuditorSummaryPDF = React.memo(({ summary, AuditDate }) => {
    const decoder = new TextDecoder('utf-8');
    const bytes = new Uint8Array([0xC2, 0xA9, 0xEF, 0xB8, 0x8F]);
    const symbol = decoder.decode(bytes);

    if (!summary) return (
        <Page size="A4">
            <Text>Brak podsumowania</Text>
        </Page>
    )

    return (
        <Page size="A4" style={{ fontFamily: 'Open Sans', fontSize: 12, paddingTop: 30, paddingBottom: 30 }}>
            <View style={{ width: '85%', marginLeft: 'auto', marginRight: 'auto' }}>
                <Text style={{ fontSize: 24, textAlign: 'center', fontWeight: 'bold' }}>Summary</Text>
                {summary && (
                    <View style={{ marginTop: 25, width: '100%' }}>
                        {summary.auditor_comments && (
                            <Text style={{ textAlign: 'justify', fontSize: 13 }}>
                                {summary.auditor_comments}
                            </Text>
                        )}
                    </View>
                )}
                <View style={{ marginTop: 25, marginBottom: 20 }}>
                    <Text style={{ fontSize: 16, fontWeight: 'bold', marginBottom: 10 }}>Signature:</Text>
                    <View style={{ borderWidth: 1, borderColor: '#000', marginBottom: 10, padding: 2, width: 200 }}>
                        {summary.signature && <Image src={summary.signature} />}
                    </View>
                    <Text>Auditor: {summary.auditor_name}</Text>
                    <Text>Data: {formatDate(AuditDate)}</Text>
                </View>
            </View>
            <View fixed style={styles.mainfooter}>
                <View style={styles.footerLinkText}>
                    <Text>www.hotelsaudit.com</Text></View>
                <View style={styles.footerText}>
                    <Text> This document contains confidential information, do not share it with anyone outside the organization. Hotels Audit {symbol} 2025. </Text>
                </View>
                <View>
                    <Text
                        style={[styles.pageNumber]}
                        render={({ pageNumber, totalPages }) => (
                            `${pageNumber} / ${totalPages}`
                        )}
                    />
                </View>
            </View>
        </Page>
    )
});

const MyDocument = ({ sections, AuditDate, summary, FullCoverPhoto, auditTitle }) => {
    let totalQuestions = 0;
    let correctAnswersCount = 0;
    let naAnswersCount = 0;
    let wrongAnswersCount = 0;
    let noAnswers = [];

    const processGroups = (groups, sectionCopy) => {
        groups.forEach(group => {
            let groupCopy = { ...group }; // Tworzymy kopię grupy
            groupCopy.questions = group.questions.filter(question => question.answer === "no"); // Filtrujemy pytania, które mają odpowiedź "no"
            groupCopy.questions.sort((a, b) => a.id - b.id); // Sortujemy pytania według id

            if (groupCopy.questions.length > 0) { // Jeśli grupa ma pytania z odpowiedzią "no", dodajemy ją do sekcji
                sectionCopy.groups.push(groupCopy);
            }
        });

        sectionCopy.groups.sort((a, b) => a.id - b.id); // Sortujemy grupy według id
    };

    sections.forEach(section => {
        let sectionCopy = { ...section }; // Tworzymy kopię sekcji
        sectionCopy.groups = []; // Inicjalizujemy pustą tablicę grup

        // Przetwarzanie grup w sekcji głównej
        processGroups(section.groups, sectionCopy);

        // Przetwarzanie grup w podsekcjach
        if (section.subSections && section.subSections.length > 0) {
            sectionCopy.subSections = [];
            section.subSections.forEach(subSection => {
                let subSectionCopy = { ...subSection };
                subSectionCopy.groups = [];
                processGroups(subSection.groups, subSectionCopy);

                if (subSectionCopy.groups.length > 0) { // Dodajemy podsekcję tylko jeśli ma grupy z pytaniami z odpowiedzią "no"
                    sectionCopy.subSections.push(subSectionCopy);
                }
            });
            sectionCopy.subSections.sort((a, b) => a.id - b.id); // Sortujemy podsekcje według id
        }

        if (sectionCopy.groups.length > 0 || (sectionCopy.subSections && sectionCopy.subSections.length > 0)) { // Jeśli sekcja ma grupy lub podsekcje z pytaniami z odpowiedzią "no", dodajemy ją do noAnswers
            noAnswers.push(sectionCopy);
        }
    });

    sections.forEach(section => {
        const countAnswers = (groups) => {
            groups.forEach(group => {
                group.questions.forEach(question => {
                    if (question.answer !== null) {
                        totalQuestions += 1;
                        if (question.answer === "yes") {
                            correctAnswersCount += 1;
                        }
                        if (question.answer === "na") {
                            naAnswersCount += 1;
                        }
                        if (question.answer === "no") {
                            wrongAnswersCount += 1;
                        }
                    }
                });
            });
        };

        // Przetwarzanie grup w sekcji głównej
        countAnswers(section.groups);

        // Przetwarzanie grup w podsekcjach
        if (section.subSections && section.subSections.length > 0) {
            section.subSections.forEach(subSection => {
                countAnswers(subSection.groups);
            });
        }
    });

    const percentageScore = totalQuestions > 0 ? (correctAnswersCount / (totalQuestions - naAnswersCount)) * 100 : 0;

    let certificateImageSrc;
    if (percentageScore >= 95) {
        certificateImageSrc = diamond;
    } else if (percentageScore >= 90) {
        certificateImageSrc = platinum;
    } else if (percentageScore >= 85) {
        certificateImageSrc = gold;
    }

    const calculateSectionScores = (sections) => {
        const scores = {};

        sections.forEach(section => {
            let sectionCorrectAnswersCount = 0;
            let sectionTotalAnsweredQuestions = 0;
            let sectionNaAnswersCount = 0;

            const processGroups = (groups) => {
                groups.forEach(group => {
                    group.questions.forEach(question => {
                        if (question.answer !== null) {
                            sectionTotalAnsweredQuestions += 1;
                            if (question.answer === "yes") {
                                sectionCorrectAnswersCount += 1;
                            }
                            if (question.answer === "na") {
                                sectionNaAnswersCount += 1;
                            }
                        }
                    });
                });
            };

            processGroups(section.groups);

            if (section.subSections && section.subSections.length > 0) {
                section.subSections.forEach(subSection => {
                    processGroups(subSection.groups);
                });
            }

            const score = sectionTotalAnsweredQuestions > 0 ? (sectionCorrectAnswersCount / (sectionTotalAnsweredQuestions - sectionNaAnswersCount)) * 100 : 0;
            scores[section.name] = score;
        });

        return scores;
    };

    const sectionScores = calculateSectionScores(sections);
    let globalQuestionNumber = 0; // Globalny licznik pytań

    const decoder = new TextDecoder('utf-8');
    const bytes = new Uint8Array([0xC2, 0xA9, 0xEF, 0xB8, 0x8F]);
    const symbol = decoder.decode(bytes);

    const calculateFontSize = (title) => {
        const titleLength = title.length;
        let fontSize = 48;

        if (titleLength >= 18 && titleLength < 24) {
            fontSize = 36;
        } else if (titleLength >= 24 && titleLength < 28) {
            fontSize = 32;
        } else if (titleLength >= 28) {
            fontSize = 28;
        }

        return fontSize;
    };
    const fontSize = calculateFontSize(auditTitle);

    const calculateNoAnswersCount = (sections) => {
        const noAnswersCount = {};

        sections.forEach(section => {
            let sectionNoAnswersCount = 0;

            const processGroups = (groups) => {
                groups.forEach(group => {
                    group.questions.forEach(question => {
                        if (question.answer === "no") {
                            sectionNoAnswersCount += 1;
                        }
                    });
                });
            };

            processGroups(section.groups);

            if (section.subSections && section.subSections.length > 0) {
                section.subSections.forEach(subSection => {
                    processGroups(subSection.groups);
                });
            }

            noAnswersCount[section.name] = sectionNoAnswersCount;
        });

        return noAnswersCount;
    };

    const noAnswersCount = calculateNoAnswersCount(sections);
    const maxNoAnswers = Math.max(...Object.values(noAnswersCount));
    const steps = 4; // Stała liczba kroków (5 - 1, bo zaczynamy od zera)
    const stepValue = Math.ceil(maxNoAnswers / steps); // Obliczamy wartość kroku

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.title}>
                    <Text>HOTELS AUDIT</Text>
                </View>
                <View style={styles.certification}>
                    <Text>CERTIFICATION</Text>
                </View>
                {FullCoverPhoto &&
                    <View style={styles.imageContainer}>
                        <Image style={styles.image} src={FullCoverPhoto} />
                    </View>
                }
                <View style={styles.hotelName}>
                    <Text style={{ fontSize: fontSize, whiteSpace: 'nowrap' }}>{auditTitle}</Text>
                </View>

                <View style={styles.scoreContainer}>
                    <View style={styles.scoreItem}>
                        <Text style={styles.scoreText}>Ilość opracowanych sugestii</Text>
                        <Text style={styles.scoreValue}>{wrongAnswersCount}</Text>
                    </View>
                    <View style={styles.scoreItem}>
                        <Text style={styles.scoreText}>Ilość sprawdzonych czynników</Text>
                        <Text style={styles.scoreValue}>{totalQuestions}</Text>
                    </View>
                </View>

                <View style={{ position: 'absolute', bottom: 70 }}>
                    {ceoSignature && <Image style={{ width: 55, height: 55 }} src={ceoSignature} />}
                </View>
                <View style={styles.signature}>
                    <Text>SIGNATURE</Text>
                </View>
                <View style={styles.auditDate}>
                    <Text>Audit date</Text>
                    <Text style={styles.auditDateValue}>{formatDate(AuditDate)}</Text>
                </View>
                <Text style={styles.footer}>WWW.HOTELSAUDIT.COM</Text>
                <Image style={styles.backgroundImage} src={backgroundImage} />
            </Page>
            {/* PAGE 2 */}
            {/* <Page size="A4" style={styles.page}>
                <View fixed style={{ justifyContent: 'space-between', flexDirection: 'row', width: '100%', paddingTop: 20, height: 40 }}>
                    <Image style={[styles.logo, { marginLeft: 45 }]} src={Logo} />
                    <Text style={{ fontSize: 12, paddingRight: 40, fontWeight: '600', letterSpacing: 1 }}>Hotels Audit</Text>
                </View>
                <View style={styles.box}>
                    <Text style={styles.statsTitle}>Statystyki</Text>
                    <Text style={styles.startSubtitle}>Podsumowanie wszystkich sekcji</Text>
                    <Text style={styles.startSubSubtitle}>Ilość wykrytych problemów i opracowanych sugetii</Text>
                    <View style={styles.bars}>
                        <View style={styles.scaleContainer}>
                            {[...Array(steps + 1)].map((_, index) => (
                                <View
                                    key={index}
                                    style={[
                                        styles.scaleLine,
                                        { left: `${(index / steps) * 100}%` },
                                    ]}
                                />
                            ))}
                        </View>
                        {Object.entries(noAnswersCount).map(([sectionName, count], index) => {
                            // Obliczamy szerokość z zabezpieczeniem przed NaN
                            const barWidth =
                                maxNoAnswers > 0 ? `${(count / maxNoAnswers) * 100}%` : '0%';
                            return (
                                <View
                                    key={`no-answer-${index}`}
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        marginBottom: 5,
                                        width: '100%',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Text
                                        style={[
                                            styles.statsLabel,
                                            { width: '20%', textAlign: 'right', marginRight: 10 },
                                        ]}
                                    >
                                        {sectionName || 'Brak nazwy'}
                                    </Text>
                                    <View style={[styles.statsBarContainer, { flex: 1, width: '70%' }]}>
                                        <View style={[styles.mainStartsBar, { width: barWidth }]}>
                                            <Text style={styles.mainStatsBarText}>
                                                {count || 0}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                            );
                        })}
                        <View style={styles.scaleLabelContainer}>
                            {[...Array(steps + 1)].map((_, index) => (
                                <Text key={index} style={styles.scaleLabel}>{index * stepValue}</Text>
                            ))}
                        </View>
                    </View>
                    <View style={{ alignItems: 'center', marginTop: 40 }}>
                        <Text style={{ fontSize: 10, textAlign: 'justify' }}>Instrukcja korzystania z raportu: niektóre odpowiedzi zawierają załączone zdjęcia z badania w obiekcie. Aby otworzyć takie zdjęcie w pełnej rozdzielczości proszę nacisnąć na nie prawym przyciskiem myszy i wybrać opcję otworzenia go w przeglądarce.</Text>
                    </View>
                </View>
                <View fixed style={styles.mainfooter}>
                    <View style={styles.footerLinkText}>
                        <Text>www.hotelsaudit.com</Text></View>
                    <View style={styles.footerText}>
                        <Text> This document contains confidential information, do not share it with anyone outside the organization. Hotels Audit {symbol} 2024. </Text>
                    </View>
                    <View>
                        <Text
                            style={[styles.pageNumber]}
                            render={({ pageNumber, totalPages }) => (
                                `${pageNumber} / ${totalPages}`
                            )}
                        />
                    </View>
                </View>
            </Page> */}
            {/* PAGE 3 */}
            <Page size="A4" style={[styles.page, { paddingBottom: 40 }, { paddingTop: 10 }]}>
                {/* STRONA 4 GENEROWANIE PYTAN */}
                {noAnswers.map((section, sectionIndex) => {
                    return (
                        <View style={styles.box} key={sectionIndex}>
                            <View style={styles.ActionPlanSectionHeader}>
                                <Image style={styles.logo} src={Logo} />
                                <Text style={styles.sectionName}>{section.name}</Text>
                                <Text style={styles.hotelsAuditText}>Hotels Audit</Text>
                            </View>
                            {section.groups.map((group, groupIndex) => {
                                let totalQuestions = group.questions.length; // Liczba pytań z odpowiedzią "no"
                                return (
                                    <View key={groupIndex}>
                                        <Text wrap={false} style={{ marginTop: 15, fontSize: 13.5, marginBottom: 10, fontWeight: 'bold', textAlign: 'center', borderBottom: '2px dashed #ccc', borderTop: '2px dashed #ccc', paddingBottom: 10, paddingTop: 10 }}>
                                            {group.name} | {totalQuestions} {totalQuestions === 1 ? 'suggestion' : 'suggestions'} found
                                        </Text>
                                        {group.questions.map((question, questionIndex) => (
                                            <QuestionSection
                                                key={questionIndex}
                                                questionNumber={questionIndex + 1}
                                                question={question.question}
                                                answer={question.answer}
                                                aiResponse={question.ai_solution}
                                                note={question.note}
                                                photoSrc={question.photos ? question.photos.map(photo => photo) : []}
                                            />
                                        ))}
                                    </View>
                                );
                            })}
                            {section.subSections && section.subSections.length > 0 && (
                                section.subSections.map((subSection, subSectionIndex) => (
                                    <View key={subSectionIndex}>
                                        <Text wrap={false} style={styles.SubSectionHeader}>
                                            {subSection.name}
                                        </Text>

                                        {subSection.groups.map((group, groupIndex) => {
                                            let totalQuestions = group.questions.length; // Liczba pytań z odpowiedzią "no"

                                            return (
                                                <View key={groupIndex}>
                                                    <Text wrap={false} style={{ marginTop: 15, fontSize: 13.5, marginBottom: 10, fontWeight: 'bold', textAlign: 'center', borderBottom: '2px dashed #ccc', borderTop: '2px dashed #ccc', paddingBottom: 10, paddingTop: 10 }}>
                                                        {group.name} | {totalQuestions} {totalQuestions === 1 ? 'suggestion' : 'suggestions'} found
                                                    </Text>
                                                    {group.questions.map((question, questionIndex) => (
                                                        <QuestionSection
                                                            key={questionIndex}
                                                            questionNumber={questionIndex + 1}
                                                            question={question.question}
                                                            answer={question.answer}
                                                            aiResponse={question.ai_solution}
                                                            note={question.note}
                                                            photoSrc={question.photos ? question.photos.map(photo => photo) : []}
                                                        />
                                                    ))}
                                                </View>
                                            );
                                        })}
                                    </View>
                                ))
                            )}

                        </View>
                    );
                })}
                <View fixed style={styles.mainfooter}>
                    <View style={styles.footerLinkText}>
                        <Text>www.hotelsaudit.com</Text></View>
                    <View style={styles.footerText}>
                        <Text> This document contains confidential information, do not share it with anyone outside the organization. Hotels Audit {symbol} 2025. </Text>
                    </View>
                    <View>
                        <Text
                            style={[styles.pageNumber]}
                            render={({ pageNumber, totalPages }) => (
                                `${pageNumber} / ${totalPages}`
                            )}
                        />
                    </View>
                </View>
            </Page>
            <AuditorSummaryPDF summary={summary} AuditDate={AuditDate} />
        </Document>
    );
};

export default MyDocument;
